var service = {};

if (process.env.NODE_ENV === 'production') {
    service.API_URL = 'https://api.hrms.mksdigitech.com';
    // service.API_URL = 'https://hrms-react-client.vercel.app';
} else {
    service.API_URL = 'http://192.168.29.109:5000';
}

export default service;
